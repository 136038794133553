import React from "react";
import Layout from "../../components/Layout";
import Seo from "../../components/Seo";
import loadable from "@loadable/component";
import LazyLoadSection from "../../components/LazyLoadSection";
import StaticSection from "../../components/sections/PublishersIndexStatic";

const AsyncSection = loadable(() =>
  import("../../components/sections/PublishersIndexDefer")
);

const IndexPage = () => (
  <Layout>
    <Seo
      title="Leading Commerce Solution for Publishers"
      description="StackCommerce offers branded e-commerce shops, affiliate commerce, and advertising solutions to help publishers monetize."
    />

    <LazyLoadSection static={<StaticSection />}>
      <AsyncSection />
    </LazyLoadSection>
  </Layout>
);

export default IndexPage;
