import React from "react";
import HomeLanding from "../HeroLanding";
import { graphql, Link, useStaticQuery } from "gatsby";
import OutlinedText from "../styles/OutlinedText";
import { Headline80 } from "../styles/Headings";
import BlackHeroWithRightLines from "../BlackHeroWithRightLines";
import HeroCTA from "../HeroCTA";
import LandingGridAnimation from "../LandingGridAnimation";

const PublishersIndexStatic = () => {
  const Images = useStaticQuery(graphql`
    query PublishersIndexStatic {
      one: file(relativePath: { eq: "13-publishers-home-1.jpg" }) {
        childImageSharp {
          fluid(quality: 80) {
            ...GatsbyImageSharpFluid_withWebp
            ...GatsbyImageSharpFluidLimitPresentationSize
          }
        }
      }
      two: file(relativePath: { eq: "13-publishers-home-2.jpg" }) {
        childImageSharp {
          fluid(quality: 80) {
            ...GatsbyImageSharpFluid_withWebp
            ...GatsbyImageSharpFluidLimitPresentationSize
          }
        }
      }
      three: file(relativePath: { eq: "13-publishers-home-3.jpg" }) {
        childImageSharp {
          fluid(quality: 80) {
            ...GatsbyImageSharpFluid_withWebp
            ...GatsbyImageSharpFluidLimitPresentationSize
          }
        }
      }
      four: file(relativePath: { eq: "13-publishers-home-4.jpg" }) {
        childImageSharp {
          fluid(quality: 80) {
            ...GatsbyImageSharpFluid_withWebp
            ...GatsbyImageSharpFluidLimitPresentationSize
          }
        }
      }
    }
  `);

  return (
    <>
      <HomeLanding
        link={{
          label: "Sign Up",
          attrs: { to: "/contact/?smopt=publisher", state: { from: "publisher" }, as: Link }
        }}
        theme={{
          bottomSize: "10px"
        }}
        animationComponent={<LandingGridAnimation Images={Images} />}
      >
        Affiliate commerce, <br />
        <OutlinedText fill="transparent" component={Headline80} as="span">
          REDEFINED
        </OutlinedText>
      </HomeLanding>

      <BlackHeroWithRightLines title="EXPERTISE" titleElement="h2">
        <p>
          Whether you’re an online media site, a social influencer, or a
          broadcast media publisher, our tools grow your revenue without
          compromising your audience experience.
        </p>
      </BlackHeroWithRightLines>

      <HeroCTA title="Our product" outline="Offerings" />
    </>
  );
};

export default PublishersIndexStatic;
